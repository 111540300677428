import './bootstrap';
import '../css/app.css';
import { createRoot } from 'react-dom/client';
import { createInertiaApp, router } from '@inertiajs/react';
import { resolvePageComponent } from 'laravel-vite-plugin/inertia-helpers';
import 'dayjs/locale/pt-br';
import dayjs from 'dayjs';
import relativeTime from 'dayjs/plugin/relativeTime';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';
import 'react-toastify/dist/ReactToastify.css';
import { H } from 'highlight.run';
import { ErrorBoundary } from '@highlight-run/react';
import './requestNotificationPermission';

dayjs.locale('pt-br');
dayjs.extend(relativeTime);
dayjs.extend(utc);
dayjs.extend(timezone);
dayjs.tz.setDefault('America/Fortaleza');

const appName = import.meta.env.VITE_APP_NAME || 'Runlife';

//@ts-ignore
if (process.env.NODE_ENV === 'production') {
  /**
   * Track errors with Highlight
   * */
  H.init('neyjv4ve', {
    serviceName: 'runlife.com.br',
    tracingOrigins: true,
    networkRecording: {
      enabled: true,
      recordHeadersAndBody: true,
      urlBlocklist: [
        // insert full or partial urls that you don't want to record here
        // Out of the box, Highlight will not record these URLs (they can be safely removed):
        'https://www.googleapis.com/identitytoolkit',
        'https://securetoken.googleapis.com'
      ]
    }
  });
  /**
   * Track Page and Send to Google Analytic
   * */
  router.on('navigate', (event) => {
    // @ts-ignore
    gtag('js', new Date());
    // @ts-ignore
    gtag('config', 'G-YTX3E502Z2');
  });
}

createInertiaApp({
  title: (title) => `${title} - ${appName}`,
  resolve: (name) =>
    resolvePageComponent(`./Pages/${name}.tsx`, import.meta.glob('./Pages/**/*.tsx')),
  setup({ el, App, props }) {
    const root = createRoot(el);

    root.render(
      <ErrorBoundary>
        <App {...props}></App>
      </ErrorBoundary>
    );
  },
  progress: {
    color: '#4B5563'
  }
});
