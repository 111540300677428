// Function to request notification permission
export function requestNotificationPermission() {
  if ('Notification' in window) {
    // Check if the permission has not been granted yet
    if (Notification.permission === 'default') {
      Notification.requestPermission()
        .then((permission) => {
          if (permission === 'granted') {
            console.log('Notification permission granted.');
          } else if (permission === 'denied') {
            console.log('Notification permission denied.');
          }
        })
        .catch((error) => {
          console.error('Error requesting notification permission:', error);
        });
    } else {
      console.log(`Notification permission already ${Notification.permission}.`);
    }
  } else {
    console.error('This browser does not support notifications.');
  }
}

// Call the function when the app renders for the first time
document.addEventListener('DOMContentLoaded', () => {
  requestNotificationPermission();
});
